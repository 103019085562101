import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Image.module.scss';

const Image = ({
  imagePath,
  imageAlterText,
  spaceBetweenImages,
}) => {
  return (
    <img
      src={imagePath}
      alt={imageAlterText}
      className={classNames(styles.image, styles.absolute)}
      style={{
        padding: spaceBetweenImages && `0 ${spaceBetweenImages}px`,
      }}
    />
  );
};

Image.propTypes = {
  imagePath: PropTypes.string.isRequired,
  imageAlterText: PropTypes.string.isRequired,
  spaceBetweenImages: PropTypes.number,
};

export default Image;
