const dummyImage = {
    titleText: '',
    titleTextColor: '',
    subtitleText: '',
    subtitleTextColor: '',
    buttonText: '',
    buttonTextColor: '',
    buttonLink: '',
    imagePath: '',
    link: '',
    imageAlterText: '',
    buttonBottomBorderColor: '',
    buttonBackgroundHoverColor: '',
    buttonBottomBorderHoverColor: '',
    buttonBackgroundColor: '',
    buttonPosition: 0,
};

export {
    dummyImage,
};
