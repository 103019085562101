import React from 'react';
import { CustomAppendDots } from '../../components/dotNavigator';

const structureDotNavigatorSettings = (dotNavigator, setActiveDotIndex) => {
  const { isDotNavigatorVisible, isIncludeInside, iconHeight } = dotNavigator;

  const dotNavigatorSettings = {
    dots: isDotNavigatorVisible,
    appendDots: dots => (
      <CustomAppendDots
        dots={dots}
        setActiveDotIndex={setActiveDotIndex}
        isIncludeInside={isIncludeInside}
        iconSize={iconHeight}
      />
    ),
  };

  return dotNavigatorSettings;
};

export default structureDotNavigatorSettings;
