exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__Image_image{width:100%;height:100%;-o-object-fit:cover;object-fit:cover}.ImageSliderContentBlock__Image_absolute{position:absolute;top:0;left:0;right:0;bottom:0}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ImageSliderContentBlock__Image_absolute{transform:scaleY(1.4)}}", ""]);

// exports
exports.locals = {
	"image": "ImageSliderContentBlock__Image_image",
	"absolute": "ImageSliderContentBlock__Image_absolute"
};