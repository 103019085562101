import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CustomAppendDots.module.scss';

const CustomAppendDots = ({
  dots,
  setActiveDotIndex,
  isIncludeInside,
  iconSize,
}) => {
  return (
    <ul
      className={
        isIncludeInside
          ? classNames(styles.dotsWrapper, styles.dotsInside)
          : styles.dotsWrapper
      }
    >
      {dots.map((item, index) => {
        if (item.props.className) {
          setActiveDotIndex(index);
        }
        return (
          <li
            key={index}
            className={classNames(styles.dot, 'image-slider-dot-nav')}
            style={{ width: `${iconSize}px` }}
          >
            {item.props.children}
          </li>
        );
      })}
    </ul>
  );
};

CustomAppendDots.propTypes = {
  dots: PropTypes.array,
  setActiveDotIndex: PropTypes.func,
  isIncludeInside: PropTypes.bool,
  iconSize: PropTypes.number,
};

export default CustomAppendDots;
