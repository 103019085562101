exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__CustomAppendDots_dots-wrapper{position:relative;display:block;width:100%;padding:0;margin:16px 0;list-style:none;text-align:center;line-height:0}.ImageSliderContentBlock__CustomAppendDots_dot{display:inline-block;margin:0 8px;padding:0;cursor:pointer}.ImageSliderContentBlock__CustomAppendDots_dots-inside{position:absolute;bottom:0}", ""]);

// exports
exports.locals = {
	"dots-wrapper": "ImageSliderContentBlock__CustomAppendDots_dots-wrapper",
	"dotsWrapper": "ImageSliderContentBlock__CustomAppendDots_dots-wrapper",
	"dot": "ImageSliderContentBlock__CustomAppendDots_dot",
	"dots-inside": "ImageSliderContentBlock__CustomAppendDots_dots-inside",
	"dotsInside": "ImageSliderContentBlock__CustomAppendDots_dots-inside"
};